import { useState, useEffect } from 'react'
import { Box, CardContent, Typography, CircularProgress } from '@material-ui/core'
import { useSubscription } from '@apollo/client'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { styled } from '@mui/material/styles'

import { updatedFile } from '../Upload-v2/queries.graphql'

const CardContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  boxShadow: `0px 0px 20px ${theme.palette.background.shadows}30`,
  borderRadius: '5px',
  height: '175px',
  width: '140px',
  backgroundColor: theme.palette.background.main,
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 6px 30px rgba(175, 175, 175, 0.25)',
    borderRadius: '12px',
    height: 'auto',
    width: '180px',
  },
  '& .MuiCardMedia-root': {
    height: '90px',
    [theme.breakpoints.up('md')]: {
      height: '200px',
    },
  },
  '& .MuiCardContent-root': {
    padding: '4px',
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    '& .fileSize': {
      fontSize: 10,
      color: theme.palette.background.contrastText,
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
  },
}))

const LoadingContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '90px',
  justifyContent: 'center',
  alignItems: 'center',
}))

const CardContentElement = styled(props => <CardContent {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const FileName = styled(props => <Typography {...props} />)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textWrap: 'balance',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
  '& a': {
    textDecoration: 'none',
    color: theme.palette.primary.black,
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: '14px',
    },
  },
  '& svg': {
    fontSize: '1.2rem',
    color: theme.palette.background.contrastText,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  },
}))

export const UploadingFiles = ({ uploadedFile, onRemove, setPictures }) => {

  const [data, setData] = useState(uploadedFile)

  const updatedEvent = useSubscription(updatedFile, {
    shouldResubscribe: true,
    lazy: true,
    variables: { id: data.id },
  })

  const formatFileName = (fileName) => {
    if (!fileName) return ''
    const [name, extension] = fileName.split(/(?=\.[^.]+$)/)
    if (name.length <= 14) return fileName
    return `${name.substring(0, 8)}...${name.substring(name.length - 4)}${extension}`
  }

  useEffect(() => {
    if (!updatedEvent) return
    if (updatedEvent.loading) return
    if (!updatedEvent.data) return
    if (!updatedEvent.data.updatedFile) return
    setData(updatedEvent.data.updatedFile)
  }, [updatedEvent])

  /*
    1- Inicializando
    2- Inactivo
    3- Procesando
    4- Listo
    5- Error De Proceso
    6- Cargando
    7- Error De Carga
    8- Esperando En La Fila
    9- Error al eliminar
    */

  useEffect(() => {
    if (data.status.id !== '4') return
    setPictures(pictures => [...pictures, data])
  }, [data])

  return (
    <>
      {data.status.id !== '4' && (
        <CardContainer>
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
          <CardContentElement>
            <Box>
              <FileName>{formatFileName(data.name)}</FileName>
              <Typography>{data.status.name}</Typography>
            </Box>
            <HighlightOffIcon onClick={onRemove} />
          </CardContentElement>
        </CardContainer>
      )}
    </>
  )
}
