/* eslint-disable max-len */
import { useGlossary, useTheme } from '@hooks/index'
import { CustomDivider, GreenButton, GreenOutlinedButton } from '@App/Components'
import TicketsCounter from '../components/TicketsCounter'
import TicketDeliveryMethod from '../components/TicketDeliveryMethod'
import AmenitiesSelect from '../components/AmenitiesSelect'
import { ActionsContainer, ActionsContent, BoxContainer, BoxContent, SecondLabel, Text, SectionContainer } from '../components/StyledComponents'

export const BoxCheckoutMobileStep2 = ({ changeHandlers, formikInstances, store, onBack, onNext, amenityList }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()

  const { handleAmenitylist } = changeHandlers

  return (
    <BoxContainer>
      <BoxContent>
        <TicketsCounter type={store.type} formik={formikInstances.seats}/>
        <CustomDivider/>
        <SectionContainer>
          <SecondLabel sx={{ marginTop: '8px' }}>{glossary('TicketDeliveryMethod')}</SecondLabel>
          <TicketDeliveryMethod store={store} formik={formikInstances.ticketDelivery}/>
        </SectionContainer>
        {store.type === '1' && (<>
          <CustomDivider/>
          <SectionContainer>
            <SecondLabel>{glossary('Amenities')}</SecondLabel>
            <Text sx={{ color: theme.palette.gray[600] }}>{glossary('SelectSixAmenities')}</Text>
            <AmenitiesSelect formik={formikInstances.amenities} amenityList={amenityList}
              amenityHandler={handleAmenitylist}/>
          </SectionContainer>
        </>)
        }
      </BoxContent>
      <ActionsContainer>
        <ActionsContent>
          <GreenOutlinedButton style={{ width: '100%', height: 40 }} onClick={onBack}>
            {glossary('Back')}
          </GreenOutlinedButton>
          <GreenButton style={{ width: '100%', height: 40 }} onClick={onNext}>
            {glossary('Continue')}
          </GreenButton>
        </ActionsContent>
      </ActionsContainer>
    </BoxContainer>
  )
}
