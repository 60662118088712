import { Fragment, useState, useEffect, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import { Autocomplete, Box, TextField, useMediaQuery, MenuItem } from '@mui/material'

import { useGlossary, useDebounce } from '@hooks/index'
import { CustomSelect } from '@App/Components'
import useBankFields from '@features/App/hooks/useBankFields'
import { CheckboxElement } from './BoxCheckoutMobile'
import { useTheme } from '@App/hooks'

const Label = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
  textAlign: 'left',
}))

const SubLabel = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 12,
  color: theme.palette.gray[600],
  textAlign: 'left',
}))

const BoxData = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  [theme.breakpoints.up('md')]:{
    width: '48%',
    padding: theme.spacing(0),
  },
}))

const BoxSelect = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  [theme.breakpoints.up('md')]:{
    padding: theme.spacing(0),
  },
}))

const BoxAutoComplete = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '45%',
  },
}))

const BoxBank = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  [theme.breakpoints.up('md')]:{
    padding: theme.spacing(0),
    gap: theme.spacing(2),
  },
}))

const BoxInputs = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  [theme.breakpoints.up('md')]:{
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '1 1 50%',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
}))

const OwnerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

const Ghost = styled(Box)(({ theme }) => ({
  width: '100%',
}))

const BoxOwner = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(0),
  gap: theme.spacing(1),
  width: '100%',
}))

const SelectLabel = styled(props => <Box {...props}/>)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  font: theme.font.roboto.paragraph.medium,
  width: '100%',
  opacity: 0.75,
}))

export const BankData = ({ store, country, setCountry, formik, boxBankFields, 
  setBoxBankFields }) => {

  const { theme } = useTheme()
  const  isMd = useMediaQuery(theme.breakpoints.up('md'))
  const { glossary } = useGlossary()
  const { getCountriesBankFields, getBankFieldByCountry } = useBankFields()

  const [countriesOps, setCountriesOps] = useState([])
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  const updateBankFields = useCallback(newObject => {
    setBoxBankFields(prevArray => {
      const existingIndex = prevArray.findIndex(obj => obj.name_en === newObject.name_en)
      if (existingIndex !== -1) {
        // Replace existing object
        const updatedArray = [...prevArray]
        updatedArray[existingIndex] = newObject
        return updatedArray
      } else {
        // Add new object
        return [...prevArray, newObject]
      }
    })
  }, [boxBankFields, setBoxBankFields])
  
  const deleteFromArray = useCallback(objectToDelete => {
    setBoxBankFields(prevArray => {
      return prevArray.filter(obj => obj.name_en !== objectToDelete.name_en)
    })
  }, [setBoxBankFields])

  const handleChangeBankFields = (event, element) => {
    let newObject = {
      ...element,
      value: event.target.value,
    }
    if (element.validation) {
      const regex = new RegExp(element.validation)
      const tested = regex.test(newObject.value)
      newObject = { ...newObject, validated: true, isValid: Boolean(tested) }
    } else {
      newObject = { ...newObject, validated: true, isValid: true }
    }
    updateBankFields(newObject)
  }

  const fetchBankField = useCallback(countryId => {
    const variables = {
      country: countryId,
    }
    getBankFieldByCountry(variables)
      .then(data => {
        if (data.data.errors && data.data.errors.length) throw new Error(data.data.errors[0].message)
        if ((data.data.getBankFieldByCountry.country.name !== country.name) || boxBankFields.length === 0){ 
          setBoxBankFields(data.data.getBankFieldByCountry.fields)
          setCountry(data.data.getBankFieldByCountry.country)
        }   
      })
      .catch(error => console.error(error))
  }, [country, boxBankFields])

  const handleChange = (e, newValue) => {
    setCountry(newValue)
    if (newValue) {
      fetchBankField(newValue.id)
    }
  }

  useEffect(() => {
    const fetchCountries = () => {
      setIsLoading(true)
      const variables = {
        country: [],
      }
      getCountriesBankFields(variables)
        .then(data => {
          if (!data) {
            setCountriesOps([])
            return
          }
          const ops = data.map(e => e.node.country)
          setCountriesOps(ops)
        })
        .catch(error => console.error(error))
      setIsLoading(false)
    }
    fetchCountries()
  }, [country])

  useEffect(() => {
    if (country?.id) {
      fetchBankField(country.id)
    }
  }, [country])

 
  return <Fragment>
    <BoxBank>
      <BoxSelect>
        <Label>
          {glossary('SelectCountryPayment')}
        </Label>
        <BoxAutoComplete>
          <Autocomplete 
            filterSelectedOptions={true}
            options={countriesOps}
            value={country}
            getOptionLabel={option => option.name != 'Global' ? option.name : glossary('Other')}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label={glossary('SelectCountry')}
                fullWidth
                value={query}
                onChange={e => setQuery(e.target.value)}
                sx={{  '& legend': { display: 'none' },
                  '& .MuiInputLabel-shrink': { opacity: 0, transition: 'all 0.2s ease-in' } }}
              />
            )}
            onChange={handleChange}
            open={open}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            loading={debouncedQuery.length === 0 || isLoading}
            loadingText={
              isLoading
                ? glossary('Searching')
                : glossary('TypeToSearch')

            }
            noOptionsText={glossary('NoOptions')}
            sx={{ backgroundColor: theme.palette.gray[100] }}
          />
        </BoxAutoComplete>
      </BoxSelect>
      <BoxInputs>
        {
          country && boxBankFields.map((field, key) => (
            <BoxData key={key}>
              <Label>
                {glossary('Enter')} {field.name}
              </Label>
              {(field.type == 'alphanumeric' || field.type == 'numeric') && (
                <TextField 
                  id={`boxcheckout-${field.name}`}
                  required={field.isRequired}
                  onChange={e => handleChangeBankFields(e, field)}
                  defaultValue={field.value || ''}
                  label={field.description}
                  variant={'outlined'}
                  size='small'
                  sx={{ backgroundColor: theme.palette.gray[100], width: '100%', '& legend': { display: 'none' },
                    '& .MuiInputLabel-shrink': { opacity: 0, transition: 'all 0.2s ease-in' } }}
                  error={field.validated && !field.isValid}
                  helperText={field.validated && !field.isValid ? field.description : ''}
                />
              )}
              {field.type === 'select' && (
                <CustomSelect
                  onChange={e => handleChangeBankFields(e, field)}
                  value={field.value || field.description }
                  sx={{ backgroundColor: theme.palette.gray[100] }}
                  required={field.isRequired}
                  renderValue ={
                    value =>
                      <SelectLabel>
                        { value }
                      </SelectLabel>
                  }
                >
                  {field.allowedValues.split(',').map(value => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </BoxData>
          ))
        }
      </BoxInputs>
      <BoxOwner>
        <OwnerBox>
          <CheckboxElement
            checked={formik.values.acceptOwner}
            onChange={e => formik.setFieldValue('acceptOwner', e.target.checked)} />
          <SubLabel>
            {glossary('OwnerStatement')}
          </SubLabel>
        </OwnerBox>
        {
          isMd && <Ghost/>
        }
      </BoxOwner>
    </BoxBank>
  </Fragment>
}
