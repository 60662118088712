import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useGlossary } from '@features/App/hooks'
import { CheckboxElement, SectionTitleMobile } from './StyledComponents'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

const CheckContainer  = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0),
  gap: theme.spacing(1),
  width: '100%',
}))

const Label = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 12,
  color: theme.palette.gray[600],
}))

export const Services = ({ formik }) => {

  const { glossary } = useGlossary()
 
  return <>
    <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
      <SectionTitleMobile>{glossary('StadiboxServices')}</SectionTitleMobile>
      <CheckContainer >
        <CheckboxElement
          onChange={e => formik.setFieldValue('seasonRent', e.target.checked)}
          checked={formik.values.seasonRent}
        />
        <Label style={{ marginTop: 10 }}>
          {glossary('Service1')}
        </Label>
      </CheckContainer>
      <CheckContainer sx={{ justifyContent: 'stretch' }}>
        <CheckboxElement
          onChange={e => formik.setFieldValue('adminProperty', e.target.checked)}
          checked={formik.values.adminProperty}
        />
        <Label style={{ marginTop: 10 }}>
          {glossary('Service2')}
        </Label>
      </CheckContainer>
    </Container>
  </>
}

export default Services
