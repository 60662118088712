import { useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { GreenButton, GreenOutlinedButton } from '@App/Components'
import { useGlossary } from '@hooks/index'
import FindOut from '../components/FindOut'
import Services from '../components/Services'
import { ActionsContainer, ActionsContent, BoxContainer, BoxContent } from '../components/StyledComponents'
import Resume from '../components/Resume'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

export const BoxCheckoutMobileStep8 = ({ store, formikInstances, onBack, onNext }) => {

  const { glossary } = useGlossary()
  const [disableSubmit, setDisableSubmit] = useState(false)

  return (
    <BoxContainer>
      <BoxContent>
        <Resume store={store}/>
        <Container>
          <FindOut formik={formikInstances.origin} formik2={formikInstances.originValue}/>
          <Services formik={formikInstances.contactServices}/>
        </Container>
      </BoxContent>
      <ActionsContainer>
        <ActionsContent>
          <GreenOutlinedButton style={{ width: '100%', height: 40 }} onClick={onBack}>
            {glossary('Back')}
          </GreenOutlinedButton>
          <GreenButton style={{ width: '100%', height: 40 }} disabled={disableSubmit} onClick={e => {
            onNext(e)
            setDisableSubmit(true)
          }}>
            {glossary('Continue')}
          </GreenButton>
        </ActionsContent>
      </ActionsContainer>
    </BoxContainer>
  )
}
