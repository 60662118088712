import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary } from '@features/App/hooks'
import { GreenButton, GreenOutlinedButton } from '@App/Components'
import { ActionsContainer, ActionsContent, BoxContainer, BoxContent } from '../components/StyledComponents'
import { SectionSelector } from '../components'

export const SecondLabel = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.paragraph.large,
  fontWeight: '500',
  fontSize: 16,
  color: theme.palette.gray[600],
}))

export const BoxCheckoutMobileStep1 = ({ changeHandlers, formikInstances, store, onBack, onNext }) => {
  
  const { glossary } = useGlossary()
  
  return (
    <BoxContainer>
      <BoxContent>
        <SectionSelector formik ={ formikInstances.forum} typeChangeCallback={changeHandlers.handleType}/>
      </BoxContent>
      <ActionsContainer>
        <ActionsContent>
          <GreenOutlinedButton style={{ width: '100%', height: 40 }} onClick={onBack}>
            {glossary('Back')}
          </GreenOutlinedButton>
          <GreenButton style={{ width: '100%', height: 40 }} onClick={onNext}>
            { glossary('Continue')}
          </GreenButton>
        </ActionsContent>
      </ActionsContainer>
    </BoxContainer>
  )
}
