import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { Box, Radio, Checkbox } from '@mui/material'

import { ArrowLeftIcon } from '@icons/index'
import { useGlossary, useTheme } from '@hooks/index'
import { BoxCheckoutMobileStep1 } from './step1'
import { BoxCheckoutMobileStep2 } from './step2'
import { BoxCheckoutMobileStep4 } from './step4'
import { BoxCheckoutMobileStep5 } from './step5'
import { BoxCheckoutMobileStep6 } from './step6'
import { BoxCheckoutMobileStep7 } from './step7'
import { BoxCheckoutMobileStep8 } from './step8'

const Root = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}))

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
}))

export const TitleLabel = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.paragraph.large,
  fontWeight: '500',
  fontSize: 16,
  color: theme.palette.gray[700],
}))

export const Label = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

export const SectionLabel = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

export const Text = styled('label')(({ theme }) => ({
  font: theme.font.rubik.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[700],
}))

export const Paragraph = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 12,
  color: theme.palette.gray[500],
}))

export const SectionTitle = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.subtitle.medium,
  fontWeight: '500',
  fontSize: 24,
  color: theme.palette.gray[500],
}))

export const RadioElement = styled(props => <Radio {...props} />)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}))

export const CheckboxElement = styled(props => <Checkbox {...props} />)(({ theme }) => ({
  color: theme.palette.gray[500],
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F6F6F6',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5062D3',
  },
}))

export const BoxCheckoutMobile = ({ store, dispatch, changeHandlers, formikInstances, 
  amenityList, setAmenityList, country, setCountry, boxBankFields, setBoxBankFields, 
  pictures, setPictures, fields, setFields, 
  submit }) => {

  const router = useRouter()
  const { glossary } = useGlossary()
  const { theme } = useTheme()

  const [progress, setProgress] = useState(0)

  const steps = [
    {
      step: 0,
      title: glossary('RegisterProperty'),
      content: <BoxCheckoutMobileStep1  />,
      props: { onBack: router.back, 
        onNext: () => changeHandlers.handleOnNext([formikInstances.forum],
          () => setProgress(progress + 1)),
        changeHandlers, store, 
        dispatch, formikInstances },
    },
    {
      step: 1,
      title: glossary('AddDetails'),
      content: <BoxCheckoutMobileStep2 />,
      props: {  onBack: () => changeHandlers.handleOnBack(
        [formikInstances.seats, formikInstances.ticketDelivery, formikInstances.amenities],
        () => setProgress(progress - 1)
      ),
      onNext: () => changeHandlers.handleOnNext(
        [formikInstances.seats, formikInstances.ticketDelivery, formikInstances.amenities],
        () => setProgress(progress + 1)
      ),
      changeHandlers, store, 
      dispatch, formikInstances, amenityList, setAmenityList },
    },
    {
      step: 2,
      title: glossary('AddPropertyPictures'),
      content: <BoxCheckoutMobileStep4  />,
      props: {  onBack: () => changeHandlers.handleOnBack([formikInstances.pictures],
        () => setProgress(progress - 1)), 
      onNext: () => changeHandlers.handleOnNext([formikInstances.pictures],
        () => setProgress(progress + 1)), 
      store, dispatch, formikInstances, pictures, setPictures },
    },
    {
      step: 3,
      title: glossary('BankAccount'),
      content: <BoxCheckoutMobileStep5 />,
      props: { onBack: () => changeHandlers.handleOnBack2([formikInstances.acceptOwner],
        () => setProgress(progress - 1)),  onNext: () => changeHandlers.handleOnNext2([formikInstances.acceptOwner],
        () => setProgress(progress + 1), boxBankFields={ boxBankFields }, fields={ fields },
        country={ country }), formikInstances, country, setCountry, 
      fields, setFields, boxBankFields, setBoxBankFields },
    },
    {
      step: 4,
      title: glossary('PickupAddress'),
      content: <BoxCheckoutMobileStep6 />,
      props: { onBack: () => changeHandlers.handleOnBack([formikInstances.pickupAddressId],
        () => setProgress(progress - 1)),  onNext: () => changeHandlers.handleOnNext([formikInstances.pickupAddressId],
        () => setProgress(progress + 1)), formikInstances, changeHandlers },
    },
    {
      step: 5,
      title: glossary('Documents'),
      content: <BoxCheckoutMobileStep7 />,
      props: { onBack: () => changeHandlers.handleOnBack([formikInstances.coOwners,
        formikInstances.documents],
      () => setProgress(progress - 1)),  onNext: () => changeHandlers.handleOnNext([formikInstances.coOwners, 
        formikInstances.documents],
      () => setProgress(progress + 1)), formikInstances },
    },
    {
      step: 6,
      title: glossary('Summary'),
      content: <BoxCheckoutMobileStep8 />,
      props: { onBack: () => setProgress(progress - 1), 
        onNext: () => changeHandlers.handleOnNext([formikInstances.origin, 
          formikInstances.originValue, formikInstances.contactServices], submit), formikInstances, store },
    },
  ]

  return (
    <Root>
      <Box sx={{ width: '100%', height: 60 }}>
        <BorderLinearProgress variant='determinate' value={(progress * 100) / 7} />
        <HeaderContainer>
          <ArrowLeftIcon
            color={theme.palette.grey[900]}
            onClick={() => (progress === 0 ? router.back() : setProgress(progress - 1))}
          />
          <TitleLabel>{steps[progress].title}</TitleLabel>
          <span />
        </HeaderContainer>
      </Box>
      <Box sx={{ width: '100%', height: 'calc(100% - 60px)' }}>
        {React.cloneElement(steps[progress].content, steps[progress].props)}
      </Box>
    </Root>
  )
}
