import { useState, useEffect } from 'react'
import { Box, Card, CardMedia } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'

import baseConfig from '../../../base.config'
import { useGlossary } from '@App/hooks'

const CardContainer = styled(props => <Card {...props} />)(({ theme }) => ({
  boxShadow: '1px 1px 1px rgba(175, 175, 175, 0.25)',
  borderRadius: '4px',
  height: '145px',
  width: '140px',
  backgroundColor: theme.palette.background.main,
  [theme.breakpoints.up('md')]: {
    boxShadow: '1px 1px 1px rgba(175, 175, 175, 0.25)',
    borderRadius: '12px',
    height: 'auto',
    width: '180px',
  },
  '& .MuiCardMedia-root': {
    height: '90px',
    [theme.breakpoints.up('md')]: {
      height: '180px',
      width: '180px',
    },
  },
  '& .MuiCardContent-root': {
    padding: '4px',
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    '& .fileSize': {
      fontSize: 10,
      color: theme.palette.background.contrastText,
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
  },
}))

const StyledCardMedia = styled(props => <CardMedia {...props} />)(({ theme }) => ({
  display: 'flex !important',
  alignItems: 'center',
  width: '100%',
  aspectRatio: 'calc(7 / 4)',
  backgroundSize: 'cover !important',
  backgroundPosition: 'center !important',
  backgroundRepeat: 'no-repeat !important',
}))

const BackgroundPhoto = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  width: '100%',
  height: '100%',
  background: theme.palette.gradient.linear.transparent,
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}))

const BackgroundVideo = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '180px',
  height: '100px',
}))

const DeleteContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
}))

const Label = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
}))

const Cover = styled(props => <StarIcon {...props} />)(({ theme }) => ({
  color: theme.palette.primary[500],
}))

const NoCover = styled(props => <StarBorderIcon {...props} />)(({ theme }) => ({
  color: theme.palette.primary[500],
}))

const MiniLabel = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.roboto.paragraph.minimum,
  color: theme.palette.primary.contrastText,
}))

const BoxLabel = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'end',
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  gap: theme.spacing(0.5),
}))
export const BoxFileSelector = ({ picture, index, coverImage, setCoverImage, pictures, setPictures, readOnly }) => {
  const [cover, setCover] = useState(false)
  const { glossary } = useGlossary()

  const removePicture = () => {
    const newArr = pictures
    if (newArr[index].id === coverImage) setCoverImage('')
    newArr.splice(index, 1)
    setPictures(() => [...newArr])
  }

  useEffect(() => {
    if (cover && coverImage !== picture.id) setCoverImage(picture.id)
    if (!cover && coverImage === picture.id) setCoverImage('')
  }, [cover])

  useEffect(() => {
    if (!coverImage) return
    coverImage === picture.id ? setCover(true) : setCover(false)
  }, [coverImage])

  return (
    <CardContainer sx={{ height: readOnly ? '90px' : '125px' }}>
      <StyledCardMedia
        component={'div'}
        sx={{
          background: `url('${baseConfig.previewRoot}/${picture.id}')`,
          position: 'relative'
        }}
      >
        {picture.type.class.name === 'Video' ? (
          <>
            <BackgroundVideo>
              <BoxLabel>
                {cover && <MiniLabel>{glossary('Cover')}</MiniLabel>}
                {cover ? <Cover /> : <NoCover onClick={() => !readOnly && setCoverImage(picture.id)} />}
              </BoxLabel>
              <video autoPlay muted loop>
                <source src={`${baseConfig.previewRoot}/${picture.id}`} type='video/mp4'></source>
              </video>
            </BackgroundVideo>
          </>
        )
          : <BackgroundPhoto>
            <BoxLabel>
              {cover && <MiniLabel>{glossary('Cover')}</MiniLabel>}
              {cover ? <Cover /> : <NoCover onClick={() => !readOnly && setCoverImage(picture.id)} />}
            </BoxLabel>
          </BackgroundPhoto>
        }
      </StyledCardMedia>
      {!readOnly && (
        <DeleteContainer onClick={removePicture}>
          <DeleteIcon />
          <Label>{glossary('Delete')} </Label>
        </DeleteContainer>
      )}
    </CardContainer>
  )
}
