import { useGlossary } from '@hooks/index'
import {
  ActionsContainer,
  ActionsContent,
  BoxContainer,
  BoxContent,
  SectionContainer,
  SecondLabel,
  Text
} from '../components/StyledComponents'
import DocumentsStep from '../components/Documents'
import { CustomDivider, GreenButton, GreenOutlinedButton } from '@features/App/Components'
import AddCoOwners from '../components/AddCoOwners'

export const BoxCheckoutMobileStep7 = ({ formikInstances, onBack, onNext }) => {
  
  const { glossary } = useGlossary()

  return (
    <BoxContainer>
      <BoxContent>
        <DocumentsStep formik={formikInstances.documents}/>
        <CustomDivider/>
        <SectionContainer>
          <SecondLabel sx={{ display:'flex', flexDirection: 'row', marginTop: '8px' }}>
            {glossary('CoOwners')} ({glossary('Optional')})
          </SecondLabel>
          <Text style={{ width: '100%' }}>{glossary('YouCanSkip')}</Text>
          <AddCoOwners coownersFormik={formikInstances.coOwners}
            coOwnerFormik={formikInstances.coOwnerFormik}/>
        </SectionContainer>
      </BoxContent>
      <ActionsContainer>
        <ActionsContent>
          <GreenOutlinedButton style={{ width: '100%', height: 40 }} onClick={onBack}>
            {glossary('Back')}
          </GreenOutlinedButton>
          <GreenButton style={{ width: '100%', height: 40 }} onClick={onNext}>
            {glossary('Continue')}
          </GreenButton>
        </ActionsContent>
      </ActionsContainer>
    </BoxContainer>
  )
}
