import { useEffect, useState } from 'react'
import { Box, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary, useSession } from '@features/App/hooks'
import { SectionTitleMobile } from './StyledComponents'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))
  
const SectionLabel = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

const RadioElement = styled(props => <Radio {...props} />)(({ theme }) => ({
  color: theme.palette.gray[600],
  '&.Mui-checked': {
    color: theme.palette.gray[600],
  },
}))
  
export const FindOut = ({ formik, formik2 }) => {

  const { session } = useSession()
  const { glossary } = useGlossary()

  const [origin, setOrigin] = useState(false)

  useEffect(() => {
    setOrigin(session && session.user && session.user.boxesLength > 0)
  }, [])

  return <>
    {!origin && (
      <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
        <SectionTitleMobile>{glossary('HowDidUfindOut')}</SectionTitleMobile>
        <Container style={{ width: '100%', padding: 0 }}>
          <RadioGroup
            name='origin'
            value={formik.values.origin}
            onChange={e => {
              formik.setFieldValue('origin', e.target.value)
              formik2.setFieldValue('originValue', '')
            }}
          >
            <FormControlLabel value='socialMedia' control={<RadioElement />} label={<SectionLabel> {glossary('SocialMedia')} </SectionLabel>} />
            {formik.values.origin === 'socialMedia' && (
              <RadioGroup
                name='originValue'
                value={formik2.values.originValue}
                onChange={e => formik2.setFieldValue('originValue', e.target.value)}
                sx={{ marginLeft: 3 }}
              >
                <FormControlLabel value='Facebook' control={<RadioElement />} label={<SectionLabel> {'Facebook'} </SectionLabel>} />
                <FormControlLabel value='Instagram' control={<RadioElement />} label={<SectionLabel> {'Instagram'} </SectionLabel>} />
                <FormControlLabel value='TikTok' control={<RadioElement />} label={<SectionLabel> {'TikTok'} </SectionLabel>} />
                <FormControlLabel value='Google' control={<RadioElement />} label={<SectionLabel> {'Google'} </SectionLabel>} />
              </RadioGroup>
            )}
            <FormControlLabel value='Asesor' control={<RadioElement />} label={<SectionLabel> {glossary('Adviser')} </SectionLabel>} />
            {formik.values.origin === 'Asesor' && (
              <Container style={{ flexDirection: 'column', width: '100%', padding: 0, marginLeft: 30 }}>
                <SectionLabel>{glossary('WhatsItsName')}</SectionLabel>
                <TextField
                  onChange={e => formik2.setFieldValue('originValue', e.target.value)}
                  name='originValue'
                  value={formik2.values.originValue}
                  error={formik2.touched.originValue && Boolean(formik2.errors.originValue)}
                  variant='standard'
                />
              </Container>
            )}
            <FormControlLabel value='Otro' control={<RadioElement />} label={<SectionLabel> {glossary('Other')} </SectionLabel>} />
            {formik.values.origin === 'Otro' && (
              <Container style={{ flexDirection: 'column', width: '100%', padding: 0, marginLeft: 30 }}>
                <SectionLabel>{glossary('Which')}</SectionLabel>
                <TextField
                  onChange={e => formik2.setFieldValue('originValue', e.target.value)}
                  name='originValue'
                  value={formik2.values.originValue}
                  error={formik2.touched.originValue && Boolean(formik2.errors.originValue)}
                  variant= 'standard'
                />
              </Container>
            )}
          </RadioGroup>
        </Container>
      </Container>
    )}
  </>
}

export default FindOut
