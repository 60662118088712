import { Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary, useTheme } from '@features/App/hooks'
import { SectionTitle, SectionTitleMobile, Space } from './StyledComponents'
import { DocumentFile } from '../DocumentFile'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: `${theme.spacing(2)} 0`,
  gap: theme.spacing(1),
}))

const Text = styled('label')(({ theme, error }) => ({
  font: theme.font.rubik.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: error ? 'red' : theme.palette.gray[600],
}))

const DocumentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const DocumentsStep = ({ formik }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md')) 

  return <>
    { isMd && <HeaderContainer>
      <SectionTitle>{glossary('Documents')}</SectionTitle>
    </HeaderContainer>
    }
    <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
      <SectionTitleMobile>{glossary('DocsValidation')}</SectionTitleMobile>
      <Text error={(Boolean(formik.errors.officialId) || Boolean(formik.errors.addressProof) || Boolean(formik.errors.ownershipTitle))}>
        {glossary('DocsValidationDescription')}</Text>
    </Container>
    <DocumentsContainer sx={{  ...(isMd ? { flexDirection: 'row' } : { flexDirection: 'column', gap: '16px' }) }}>
      <Container style={{ width: '100%', padding: 0 }}>
        <DocumentFile
          label={glossary('IdentityDocument')}
          file={formik.values.officialId}
          docType={formik.values.officialId}
          setDocType={doc => formik.setFieldValue('officialId', ...doc)}
          readOnly={false}
        />
      </Container>
      <Container style={{ width: '100%', padding: 0 }}>
        <DocumentFile
          label={glossary('ProofOfAddress3months')}
          file={formik.values.addressProof}
          docType={formik.values.addressProof}
          setDocType={doc => formik.setFieldValue('addressProof', ...doc)}
          readOnly={false}
        />
      </Container>
    </DocumentsContainer>
    <Container>
      <Container style={{ width: isMd ? '50%' : '100%', padding: 0 }}>
        <DocumentFile
          label={glossary('PropertyTitle')}
          file={formik.values.ownershipTitle}
          docType={formik.values.ownershipTitle}
          setDocType={doc => formik.setFieldValue('ownershipTitle', ...doc)}
          readOnly={false}
        />
      </Container>
      <Space />
    </Container>
  </>
}

export default DocumentsStep
