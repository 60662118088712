import { useRef, useContext, useState, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Box, useMediaQuery } from '@mui/material'

import Upload from '../Upload-v2'
import { UploadingFile } from './UploadingFile'
import { useTheme } from '@features/App/hooks'

export const Label = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.secondary[500],
  textAlign: 'left',
}))

const CustomButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  color: theme.palette.secondary.main,
  border: '1px dashed',
  borderColor: theme.palette.gray[500],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(3),
  cursor: 'pointer',
  width: '100%',
  alignItems: 'center',
  boxShadow: theme.shadows.elevation.medium,
  backgroundColor: theme.palette.gray[100],
  height: theme.spacing(10),
}))

export const DocumentFile = ({ label, file, docType, setDocType, readOnly }) => {

  const [{ uploadFiles }] = useContext(Upload.Context)
  const [fileToUpload, setFileToUpload] = useState([])
  const inputRef = useRef(null)

  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const removeFile = () => {
    setDocType([])
    setFileToUpload([])
  }

  const handleDrop = useCallback(
    async acceptedFile => {
      try {
        if (acceptedFile.length === 0) {
          return
        }
        const result = await uploadFiles(acceptedFile.target.files, '/')
        setFileToUpload(result)
      } catch (error) {
        console.error(error)
      }
    },
    [fileToUpload, setFileToUpload]
  )

  return (
    <>
      {(file || fileToUpload.length > 0) && (
        <UploadingFile
          uploadedFile={file || fileToUpload[0]}
          docType={docType}
          setDocType={setDocType}
          readOnly={readOnly}
          label={label}
          onRemove={removeFile}
        />
      )}
      {(!file && fileToUpload.length === 0) && (
        <CustomButton onClick={() => inputRef.current?.click()}>
          <span style={{ display: 'flex', justifyContent: 'center', marginRight: 16 }}>
            <FileUploadIcon color={'#5062D3'} />
          </span>
          <Label>{label}</Label>
        </CustomButton>
      )}
      <input ref={inputRef} type='file' accept={['image/*', 'application/pdf']} onChange={handleDrop} hidden />
    </>
  )
}
