import { useState, useEffect } from 'react'
import { Box, CardContent, Typography, CircularProgress } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { styled } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { useSubscription } from '@apollo/client'

import { updatedFile } from '../Upload-v2/queries.graphql'
import baseConfig from '../../../base.config'
import { useTheme } from '@features/App/hooks'

const CardContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  boxShadow: `0px 0px 20px ${theme.palette.background.shadows}30`,
  borderRadius: '5px',
  [theme.breakpoints.down('md')]:{
    width: '300px',
  },
  backgroundColor: theme.palette.background.main,
  '& .MuiCardContent-root': {
    padding: '4px',
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    '& .fileSize': {
      fontSize: 10,
      color: theme.palette.background.contrastText,
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
  },
}))

const LoadingContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '90px',
  justifyContent: 'center',
  alignItems: 'center',
}))

const FileContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '90px',
  justifyContent: 'center',
  alignItems: 'center',
}))

const CardContentElement = styled(props => <CardContent {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const FileName = styled(props => <Typography {...props} />)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
  '& a': {
    textDecoration: 'none',
    color: theme.palette.primary.black,
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: '14px',
    },
  },
  '& svg': {
    fontSize: '1.2rem',
    color: theme.palette.background.contrastText,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  },
}))

const TextElement = styled('label')(({ theme }) => ({
  font: theme.font.rubik.paragraph.small,
  fontWeight: '600',
  color: theme.palette.gray[600],
  marginRight: 10,
  textWrap: 'balance',
}))

export const UploadingFile = ({ label, uploadedFile, docType, setDocType, onRemove, readOnly }) => {

  const [data, setData] = useState(uploadedFile)
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const updatedEvent = useSubscription(updatedFile, {
    shouldResubscribe: true,
    lazy: true,
    variables: { id: data.id },
  })

  const formatFileName = (fileName) => {
    if (!fileName) return ''
    const [name, extension] = fileName.split(/(?=\.[^.]+$)/)
    if (name.length <= 14) return fileName
    return `${name.substring(0, 8)}...${name.substring(name.length - 4)}${extension}`
  }

  useEffect(() => {
    if (!updatedEvent) {
      return
    }
    if (updatedEvent.loading) {
      return
    }
    if (!updatedEvent.data) {
      return
    }
    if (!updatedEvent.data.updatedFile) {
      return
    }
    setData(updatedEvent.data.updatedFile)
  }, [updatedEvent])

  useEffect(() => {
    if (data.status.id !== '4') {
      return
    }
    setDocType([data])
  }, [data])

  return (
    <Box sx={{ ...(isMd ? { width: '200px' } : {}) }}>
      {data.status.id === '4' && <TextElement>{label}</TextElement>}
      <CardContainer>
        {data.status.id === '4' 
          ? (
            <FileContainer
              sx={{
                background: data.type.class.name === 'Imagen' ? `url('${baseConfig.previewRoot}/${data.id}')` : '',
                backgroundSize: 'cover',
              }}
            >
              {data.type.class.name !== 'Imagen' && <AttachFileIcon style={{ fontSize: 60 }} />}
            </FileContainer>) 
          : (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        {!readOnly && (
          <CardContentElement>
            <Box>
              <FileName>{formatFileName(data.name)}</FileName>
              <Typography>{data.status.name}</Typography>
            </Box>
            <HighlightOffIcon onClick={onRemove} />
          </CardContentElement>
        )}
      </CardContainer>
    </Box>
  )
}
