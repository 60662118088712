import { GreenButton, GreenOutlinedButton } from '@App/Components'
import { useGlossary } from '@hooks/index'
import PicturesStep from '../components/PicturesStep'
import { ActionsContainer, ActionsContent, BoxContainer, BoxContent } from '../components/StyledComponents'

export const BoxCheckoutMobileStep4 = ({ formikInstances, store, onBack, onNext, pictures, setPictures }) => {

  const { glossary } = useGlossary()
  
  return (
    <BoxContainer>
      <BoxContent>
        <PicturesStep store={store} formik={formikInstances.pictures} setPictures={setPictures} pictures={pictures}/>
      </BoxContent>
      <ActionsContainer>
        <ActionsContent>
          <GreenOutlinedButton style={{ width: '100%', height: 40 }} onClick={onBack}>
            {glossary('Back')}
          </GreenOutlinedButton>
          <GreenButton style={{ width: '100%', height: 40 }} onClick={onNext}>
            {glossary('Continue')}
          </GreenButton>
        </ActionsContent>
      </ActionsContainer>
    </BoxContainer>
  )
}
