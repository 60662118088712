import { ExpandMore } from '@mui/icons-material'
import { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import baseConfig from '@baseConfig'
import { useGlossary, useTheme } from '@features/App/hooks'
import { Label, SectionLabel, SectionTitle, Space, Text } from './StyledComponents'
import { MultipleFiles } from '../MultipleFiles'
import { Map } from '@features/Addresses'
import { DocumentFile } from '../DocumentFile'


/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))
  
const TextElement = styled(props => <Text {...props} />)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.gray[600],
  marginRight: 10,
}))

const TextElement2 = styled(props => <Text {...props} />)(({ theme }) => ({
  fontWeight: '400',
  color: theme.palette.gray[600],
  marginRight: 10,
}))
  
const SectionLabelTitle = styled(props => <SectionLabel {...props} />)(({ theme }) => ({
  fontWeight: '500',
  fontSize: 16,
  color: theme.palette.secondary.main,
}))

const LabelElement = styled(props => <Label {...props} />)(({ theme }) => ({
  marginRight: 5,
}))

const BoxDocuments = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: theme.spacing(1),
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const AccordionSummaryCustom = ({ title }) => {
  const { theme } = useTheme()

  return <AccordionSummary sx={{ paddingLeft: '8px' }}
    expandIcon={ <ExpandMore sx={{ color: theme.palette.secondary.main }}/>} >
    <SectionLabelTitle>{title}</SectionLabelTitle>
  </AccordionSummary>
}

const Section = ({ title, children, isMd, expanded, setExpanded }) => {
  if (isMd) {
    return (
      <Accordion
        elevation={0} 
        sx={{ '&:before': { height:'0px' }, marginTop: '8px' }}
        expanded={expanded === title}
        onChange={() => setExpanded(expanded === title ? null : title)}
      >
        <AccordionSummaryCustom title={title} />
        <AccordionDetails sx={{ padding: '8px' }}>{children}</AccordionDetails>
      </Accordion>
    )
  }
  return (
    <Container sx={{ marginTop: '8px' }}>
      <SectionLabelTitle>{title}</SectionLabelTitle>
      {children}
    </Container>
  )
}
export const Resume = ({ store }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const address = store.userAddresses.find(p => p.id === store.pickupAddressId)
  const [expanded, setExpanded] = useState(null)


  return <>
    <Container>
      <SectionTitle>{glossary('Summary')}</SectionTitle>
      <Space />
    </Container>
    <Container>
      <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
        <SectionLabelTitle>{glossary('Property')}</SectionLabelTitle>
        <Box>
          <TextElement>{glossary('Venue')}:</TextElement>
          <TextElement2>{store.forum?.name}</TextElement2>
        </Box>
        <Box>
          <TextElement>{glossary('PropertysNumberName')}:</TextElement>
          <TextElement2>{store.rowName}</TextElement2>
        </Box>
        <Box>
          <TextElement>{glossary('Zone')}:</TextElement>
          <TextElement2>{store.zone?.name || store.forumZoneNoMap}</TextElement2>
        </Box>
        <Box>
          <TextElement>{glossary('Section')}:</TextElement>
          <TextElement2>{store.section?.name || store.forumSectionNoMap}</TextElement2>
        </Box>
      </Container>
      <Space />
    </Container>
    <Container>
      <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
        <SectionLabelTitle>{glossary('Tickets')}</SectionLabelTitle>
        <Box>
          <TextElement>{glossary('TicketsAmount')}:</TextElement>
          <TextElement2>{store.seatNumber}</TextElement2>
        </Box>
        <Box>
          <TextElement>{glossary('Parking')}:</TextElement>
          <TextElement2>{store.parkingSpaces}</TextElement2>
        </Box>
        {store.type === '1' && <Box>
          <TextElement>{glossary('VIPParking')}:</TextElement>
          <TextElement2>{store.vipParkingSpaces}</TextElement2>
        </Box>
        }
      </Container>
    </Container>
    <Space />
    <Section title={glossary('TicketDeliveryMethodOwner')} isMd={isMd} expanded={expanded} setExpanded={setExpanded}>
      <Box>
        <TextElement>{glossary('PreferredTicketDeliveryMethodOwner')}:</TextElement>
        <TextElement2>{glossary(store.ticketDeliveryMethod==='digital' ? 'DigitalTickets' : 'PhysicalTickets')}</TextElement2>
      </Box>
    </Section>
    <Space />
    {store.type === '1' && (
      <Section title={glossary('Amenities')} isMd={isMd} expanded={expanded} setExpanded={setExpanded} >
        <Box>
          {store.amenities.map(q => (
            <LabelElement key={q}>{store.allAmenities.find(p => p.id === q.id).name}</LabelElement>
          ))}
        </Box>
      </Section>
    )}
    <Space />
    {store.coowners.length > 0 && (
      <Section title={glossary('CoOwners')} isMd={isMd} expanded={expanded} setExpanded={setExpanded}>
        {store.coowners.map(q => (
          <Box key={q}>
            <LabelElement>{q.name},</LabelElement>
            <LabelElement>{q.email}</LabelElement>
          </Box>
        ))}
      </Section>
    )}
    <Space/>
    <Section title={glossary('Pictures')} isMd={isMd} expanded={expanded} setExpanded={setExpanded}>
      <Box>
        <MultipleFiles
          coverImage={store.coverImage}
          setCoverImage={() => {}}
          pictures={store.pictures}
          setPictures={() => {}}
          readOnly={true}
        />
      </Box>
    </Section>

    <Section title={glossary('BankAccount')} isMd={isMd} expanded={expanded} setExpanded={setExpanded}>
      {
        store.bankFields.boxBankFields.map(field => (
          <Box sx={{ width: '100%' }} key={field}>
            <TextElement>{field.name}{':'}</TextElement>
            <TextElement2>{field.value}</TextElement2>
          </Box>
        ))
      }
    </Section>
    <Space />
    <Section title={glossary('PickupAddress')} isMd={isMd} expanded={expanded} setExpanded={setExpanded}>
      <Box>
        <TextElement>{glossary('Address')}</TextElement>
        <Label>
          {address.district}, {address.street}
        </Label>
      </Box>
      <Box>
        <TextElement>{glossary('CP')}:</TextElement>
        <Label>{address.zipCode}</Label>
      </Box>
      <Box>
        <TextElement>{glossary('State')}:</TextElement>
        <Label>{address.locality}</Label>
      </Box>
      <Box>
        <TextElement>{glossary('NoExt')}:</TextElement>
        <Label>{address.num}</Label>
      </Box>
      {address.numInt && <Box>
        <TextElement>{glossary('NoInt')}:</TextElement>
        <Label>{address.numInt}</Label>
      </Box>}
      <Box>
        <TextElement>{glossary('Phone')}:</TextElement>
        <Label>{address.phone}</Label>
      </Box>
    </Section>
    <Space />
    <Section title={glossary('Documents')} isMd={isMd} expanded={expanded} setExpanded={setExpanded}>
      <BoxDocuments>
        <Container style={{ width: '100%', padding: 0 }}>
          <DocumentFile
            label={glossary('IdentityDocument')}
            file={store.officialId}
            docType={store.officialId}
            setDocType={() => {}}
            readOnly={true}
          />
        </Container>
        <Container style={{ width: '100%', padding: 0 }}>
          <DocumentFile
            label={glossary('ProofOfAddress3months')}
            file={store.addressProof}
            docType={store.addressProof}
            setDocType={() => {}}
            readOnly={true}
          />
        </Container>
      </BoxDocuments>
      <BoxDocuments>
        <Container style={{ width: '100%', padding: 0 }}>
          <DocumentFile
            label={glossary('PropertyTitle')}
            file={store.ownershipTitle}
            docType={store.ownershipTitle}
            setDocType={() => {}}
            readOnly={true}
          />
        </Container>
      </BoxDocuments>
    </Section>
    <Space />
  </>
}

export default Resume
