import { useEffect } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary, useTheme } from '@features/App/hooks'
import { SectionTitle, Space, Text } from './StyledComponents'
import { MultipleFiles } from '../MultipleFiles'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

const BoxFiles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  paddingTop: theme.spacing(2),
}))

export const PicturesStep = ({ store, formik, pictures, setPictures }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md')) 

  useEffect(() => {
    formik.setFieldValue('pictures', pictures)
    if (pictures.length > 0) {
      formik.setFieldTouched('pictures', true) 
    }
  }, [pictures])

  return <>
    { isMd && <HeaderContainer>
      <SectionTitle>{glossary('AddPictures')}</SectionTitle>
    </HeaderContainer>
    }
    <Container style={{ ...(isMd ? { flexDirection: 'row' } : { padding:`${theme.spacing(2)} ${theme.spacing(0)}` }) }}>
      <Text error={formik.touched.pictures && Boolean(formik.errors.pictures)}>{ store?.type == '1' ? glossary('LoadAtLeastThreePhotos') : glossary('picturesStalls')}</Text>
      {isMd && <Space sx={{ width: '55%' }}/> }
    </Container>
    <BoxFiles>
      <MultipleFiles
        coverImage={formik.values.coverImage}
        setCoverImage={coverImage => formik.setFieldValue('coverImage', coverImage)}
        pictures={pictures}
        setPictures={setPictures}
        readOnly={false}
      />
    </BoxFiles>
  </>
}

export default PicturesStep
