import { GreenButton, GreenOutlinedButton } from '@App/Components'
import { useGlossary } from '@hooks/index'
import {
  ActionsContainer,
  ActionsContent,
  BoxContainer,
  BoxContent,
  SectionContainer,
  SectionTitleMobile
} from '../components/StyledComponents'
import AddressStep from '../components/AddressStep'
import { useRef } from 'react'

export const BoxCheckoutMobileStep6 = ({ formikInstances, onBack, onNext,
  changeHandlers }) => {

  const { glossary } = useGlossary()
  const addressStepRef = useRef(null)

  const handleNext = async () => {
    const canProceed = await addressStepRef.current?.handleNext()
    if (canProceed) {
      onNext()
    }
  }

  return (
    <BoxContainer>
      <BoxContent>
        <SectionContainer>
          <SectionTitleMobile>{glossary('PickupAddress')}</SectionTitleMobile>
          <AddressStep ref={addressStepRef} formik={formikInstances.pickupAddressId} changeHandlers={changeHandlers}/>
        </SectionContainer>
      </BoxContent>
      <ActionsContainer>
        <ActionsContent>
          <GreenOutlinedButton style={{ width: '100%', height: 40 }} onClick={onBack}>
            {glossary('Back')}
          </GreenOutlinedButton>
          <GreenButton style={{ width: '100%', height: 40 }} onClick={handleNext}>
            {glossary('Continue')}
          </GreenButton>
        </ActionsContent>
      </ActionsContainer>
    </BoxContainer>
  )
}
