import { GreenButton, GreenOutlinedButton } from '@App/Components'
import { useGlossary } from '@hooks/index'
import { ActionsContainer, ActionsContent, BoxContainer, BoxContent } from '../components/StyledComponents'
import { BankData } from '../BankData'

export const BoxCheckoutMobileStep5 = ({ formikInstances, country, setCountry, fields, setFields, acceptOwner, 
  setAcceptOwner, setBoxBankFields, boxBankFields, onBack, onNext }) => {

  const { glossary } = useGlossary()

  return (
    <BoxContainer>
      <BoxContent>
        <BankData formik={formikInstances.acceptOwner} 
          country={country} setCountry={setCountry} fields={fields} setFields={setFields} 
          acceptOwner={acceptOwner} setAcceptOwner={setAcceptOwner} boxBankFields={boxBankFields} 
          setBoxBankFields={setBoxBankFields}/>
      </BoxContent>
      <ActionsContainer>
        <ActionsContent>
          <GreenOutlinedButton style={{ width: '100%', height: 40 }} onClick={onBack}>
            {glossary('Back')}
          </GreenOutlinedButton>
          <GreenButton style={{ width: '100%', height: 40 }} onClick={onNext}>
            {glossary('Continue')}
          </GreenButton>
        </ActionsContent>
      </ActionsContainer>
    </BoxContainer>
  )
}
