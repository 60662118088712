import { useRef, useContext, useState, useEffect, useCallback } from 'react'
import { Box } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded'

import { CustomButton } from '@App/Components'
import Upload from '../Upload-v2'
import { PlusIcon } from '@icons/index'
import { BoxFileSelector } from './BoxFileSelector'
import { UploadingFiles } from './UploadingFiles'
import { useGlossary, useTheme } from '@App/hooks'

export const Label = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '500',
  fontSize: 16,
  color: theme.palette.secondary[500],
  alignSelf: 'center',
}))

export const Label2 = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '600',
  fontSize: 14,
  color: theme.palette.gray[300],
  alignSelf: 'center',
  [theme.breakpoints.down('md')]:{
    textAlign: 'center',
  }
}))

const FilesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'scroll',
  gap: 24,
  marginTop: 15,
  paddingBottom: theme.spacing(.5),
  '&::-webkit-scrollbar': {
    height: '2px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const FilesContainerReadOnly = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 24,
  marginTop: 15,
}))

const ImgButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  color: theme.palette.secondary.main,
  border: '1px dashed',
  borderColor: theme.palette.gray[500],
  borderRadius: theme.spacing(1),
  width: 'fit-content',
  padding: theme.spacing(6),
  cursor: 'pointer',
  boxShadow: theme.shadows.elevation.medium,
  [theme.breakpoints.down('md')]:{
    backgroundColor: theme.palette.gray[100], 
    width: '100%', 
  },
}))

const BoxIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& svg':{
    width: theme.spacing(5),
    height: theme.spacing(5),
    '& path':{
      color: theme.palette.secondary.main,
    }
  },
}))

const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  width: '100%',
}))

/**
 *
 * @param coverImage
 * @param setCoverImage
 * @param pictures
 * @param setPictures
 * @param readOnly
 * @param autoselectCover Boolean. If true, the first image in the pictures arreay will be automatically
 * selected as cover image.
 * @returns {JSX.Element}
 * @constructor
 */
export const MultipleFiles = ({ coverImage, setCoverImage, pictures, setPictures, readOnly, autoselectCover = true }) => {

  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const { glossary } = useGlossary()

  const [{ uploadFiles }] = useContext(Upload.Context)

  const [uploadedFiles, setUploadedFiles] = useState([])
  const inputRef = useRef(null)

  const direction = isMd ? 'horizontal' : 'vertical'

  const handleDrop = useCallback(async (event) => {
    const acceptedFiles = Array.from(event.target.files);
    const convertedFiles = [];

    try {
      for (const file of acceptedFiles) {
        if (file.type === 'image/heic') {
          // Ensure the library is loaded only in the browser
          if (typeof window !== 'undefined') {
            const heic2any = (await import('heic2any')).default;

            // Convert HEIC to JPEG
            const convertedBlob = await heic2any({
              blob: file,
              toType: 'image/jpeg',
            });

            // Create a File object from the converted Blob
            const convertedFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpeg'), {
              type: 'image/jpeg',
            });

            convertedFiles.push(convertedFile);
          } else {
            console.error('HEIC conversion attempted on the server. This should only run in the browser.');
          }
        } else {
          // Non-HEIC files
          convertedFiles.push(file);
        }
      }

      // Upload the converted and non-converted files
      const result = await uploadFiles(convertedFiles, '/');
      setUploadedFiles((uploadedFiles) => [...uploadedFiles, ...result]);
    } catch (error) {
      console.error('File upload failed:', error);
    }

    // Reset the input
    event.target.value = null;
  }, [setUploadedFiles]);


  const removeUploadedFile = index => {
    const newArr = uploadedFiles
    newArr.splice(index, 1)
    setUploadedFiles(() => [...newArr])
  }

  const onDragEnd = result => {
    if (!result.destination) return // if dropped outside the list
    const items = Array.from(pictures)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setPictures(items)
  }

  useEffect(() => {
    if (pictures.length === 0 || (coverImage && coverImage !== '') || !autoselectCover)
      return
    setCoverImage(pictures[0].id)
  }, [pictures])

  return (
    <>
      {!readOnly && (
        <BoxButton>
          <ImgButton 
            onClick={() => inputRef.current?.click()}>
            <BoxIcon>
              <AddPhotoAlternateRoundedIcon/>
            </BoxIcon>
            <Label> {glossary('AddPictures')} </Label>
            <Label2>{glossary('UploadPhotosDesc')}</Label2>
          </ImgButton>
        </BoxButton>
      )}
      {readOnly && <Box>
        <FilesContainerReadOnly>
          {pictures.length > 0 &&
            pictures.map((picture, index) => (
              <BoxFileSelector
                key={picture.id}
                picture={picture}
                index={index}
                coverImage={coverImage}
                setCoverImage={setCoverImage}
                pictures={pictures}
                setPictures={setPictures}
                readOnly={readOnly}
              />
            ))}
        </FilesContainerReadOnly>
      </Box>
      }
      {
        !readOnly && (
          <><DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='pictures-droppable' direction={direction}>
              {provided => (
                <FilesContainer {...provided.droppableProps} ref={provided.innerRef}>
                  {pictures.map((picture, index) => (
                    <Draggable key={picture.id} draggableId={picture.id} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <BoxFileSelector
                            picture={picture}
                            index={index}
                            coverImage={coverImage}
                            setCoverImage={setCoverImage}
                            pictures={pictures}
                            setPictures={setPictures}
                            readOnly={readOnly} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </FilesContainer>
              )}
            </Droppable>
          </DragDropContext><FilesContainer>
              {uploadedFiles.length > 0 &&
                uploadedFiles.map((file, index) => (
                  <UploadingFiles
                    uploadedFile={file}
                    key={file.id}
                    onRemove={() => removeUploadedFile(index)}
                    setPictures={setPictures} />
                ))}
            </FilesContainer>
          </>
        )
      }

      <input ref={inputRef} type='file' accept='image/*,video/*' onChange={handleDrop} multiple hidden />
    </>
  )
}
